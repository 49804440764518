<template>
  <div class="container">
    <topHeader :title="title"></topHeader>
    <div class="detail">
      <div class="detail-top">
        <div class="detail-top-title">支付金额</div>
        <div class="detail-top-money">
          <div v-if="item.consumeMoney > 0">
            +{{ getMoney(item.consumeMoney / 100) }}
          </div>
          <div v-if="item.consumeMoney <= 0">
            {{ getMoney(item.consumeMoney / 100) }}
          </div>
        </div>
        <div class="detail-top-items">
          <div style="font-size: 16px">支付时间</div>
          <div style="color: #666666; font-size: 16px">{{ item.endTime }}</div>
        </div>
        <CommonBorder></CommonBorder>
        <div v-if="item.useType === 1" class="detail-top-items">
          <div style="font-size: 16px">消费时长</div>
          <div style="color: #666666; font-size: 16px">
            {{ changeTimeType(item.duration) }}
          </div>
        </div>
        <div
          v-if="item.useType === 1"
          style="box-sizing: border-box; padding-left: 15px"
        >
          <div style="border-bottom: 1px solid #e5e5e5"></div>
        </div>
        <div class="detail-top-items">
          <div style="font-size: 16px">订单号</div>
          <div style="color: #666666; font-size: 16px">
            {{ item.id ? item.id : '更正' }}
          </div>
        </div>
        <CommonBorder></CommonBorder>
      </div>
      <div class="detail-bottom">
        <div style="font-size: 16px" class="detail-bottom-title">消费明细</div>
        <CommonBorder></CommonBorder>
        <div class="detail-bottom-items">
          <div style="font-size: 14px; color: #666">优惠钱包</div>
          <div>
            <span v-if="item.useCoupon && item.useCoupon > 0">
              +{{ getMoney(item.useCoupon / 100) }}
            </span>
            <span v-else-if="item.useCoupon && item.useCoupon < 0">
              {{ getMoney(item.useCoupon / 100) }}
            </span>
            <span v-else>-0.00</span>
          </div>
        </div>
        <div class="detail-bottom-items">
          <div style="font-size: 14px; color: #666">现金钱包</div>
          <div>
            <span v-if="item.useBalance && item.useBalance > 0">
              +{{ getMoney(item.useBalance / 100) }}
            </span>
            <span v-else-if="item.useBalance && item.useBalance < 0">
              {{ getMoney(item.useBalance / 100) }}
            </span>
            <span v-else>-0.00</span>
          </div>
        </div>
        <div class="detail-bottom-items">
          <div style="font-size: 14px; color: #666">补助钱包</div>
          <div>
            <span v-if="item.useAllowance && item.useAllowance > 0">
              +{{ getMoney(item.useAllowance / 100) }}
            </span>
            <span v-else-if="item.useAllowance && item.useAllowance < 0">
              {{ getMoney(item.useAllowance / 100) }}
            </span>
            <span v-else>-0.00</span>
          </div>
        </div>
      </div>
      <!-- <div class="detail-item">
        <div style="color: #333;font-weight: bold;line-height: 48px;text-align: center;">消费明细</div>
        <div style="display: flex;align-items: center;justify-content: space-between;box-sizing: border-box;padding: 0 10px;color: #333;line-height: 40px;">
          <div>
            优惠钱包
          </div>
          <div>
            <span v-if="item.useCoupon && item.useCoupon >= 0">-{{ getMoney(item.useCoupon/100) }}</span>
            <span v-else-if="item.useCoupon && item.useCoupon < 0">+{{ getMoney(item.useCoupon/100).split('-')[1] }}</span>
            <span v-else>-0.00</span>
          </div>
        </div>
        <div style="display: flex;align-items: center;justify-content: space-between;box-sizing: border-box;padding: 0 10px;color: #333;line-height: 40px;">
          <div>
            现金钱包
          </div>
          <div>
            <span v-if="item.useBalance && item.useBalance >= 0">-{{ getMoney(item.useBalance/100) }}</span>
            <span v-else-if="item.useBalance && item.useBalance < 0">+{{ getMoney(item.useBalance/100).split('-')[1] }}</span>
            <span v-else>-0.00</span>
          </div>
        </div>
        <div style="display: flex;align-items: center;justify-content: space-between;box-sizing: border-box;padding: 0 10px;color: #333;line-height: 40px;">
          <div>
            补助钱包
          </div>
          <div>
            <span v-if="item.useAllowance && item.useAllowance >= 0">-{{ getMoney(item.useAllowance/100) }}</span>
            <span v-else-if="item.useAllowance && item.useAllowance < 0">+{{ getMoney(item.useAllowance/100).split('-')[1] }}</span>
            <span v-else>-0.00</span>
          </div>
        </div>
      </div> -->
    </div>
  </div>
</template>

<script>
import cancel from '@/assets/cancel.png';
import { timeToDate, getMoney } from '../../utils/util';
import topHeader from '@/components/topHeader';
import CommonBorder from '@/components/commonBorder';
export default {
  components: { topHeader, CommonBorder },
  name: 'ConsumptionDetail',
  data() {
    return {
      cancel,
      item: {},
      title: this.$route.query.thisStatus
    };
  },
  created() {
    const { item } = this.$route.query;
    this.item = JSON.parse(item);
  },
  methods: {
    timeToDate,
    getMoney,
    toHome() {
      this.$router.back();
    },
    changeTimeType(second) {
      if (second) {
        let hours = Math.floor(second / 3600);
        let minutes = Math.floor((second % 3600) / 60);
        let seconds = second % 60;

        // 补零操作，确保单个数字前有0
        hours = hours < 10 ? '0' + hours : hours;
        minutes = minutes < 10 ? '0' + minutes : minutes;
        seconds = seconds < 10 ? '0' + seconds : seconds;

        return hours + ':' + minutes + ':' + seconds;
      } else {
        return '—';
      }
    }
  }
};
</script>

<style scoped lang="scss">
.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;

  .header {
    width: 100%;
    background: #fff;
    display: flex;
    height: 40px;
    align-items: center;
    justify-content: start;

    .header-title {
      height: 30px;
      line-height: 30px;
      font-size: 18px;
      font-weight: bold;
      color: #333;
    }
  }

  .detail {
    flex: 1;
    box-sizing: border-box;
    .detail-top {
      background: #fff;
      padding-top: 30px;
      box-sizing: border-box;
      .detail-top-title {
        text-align: center;
        font-size: 14px;
        color: #666666;
      }
      .detail-top-money {
        font-weight: bold;
        text-align: center;
        font-size: 36px;
        color: #333333;
        margin-bottom: 60px;
      }
      .detail-top-items {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        box-sizing: border-box;
        padding: 0 15px;
        line-height: 40px;
        color: #333;
        font-size: 16px;
      }
    }
    .detail-bottom {
      background: #fff;
      margin-top: 20px;
      .detail-bottom-title {
        box-sizing: border-box;
        padding: 15px;
        color: #333333;
      }
      .detail-bottom-items {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        box-sizing: border-box;
        padding: 0 15px;
        height: 32px;
        color: #666666;
        font-size: 14px;
      }
    }
    // margin: 15px;
    // background: #fff;
    // .detail-item {
    //   width: 90%;
    //   margin: 0 auto;
    //   border: 1px solid #d7d7d7;
    //   border-radius: 0.25rem;
    //   background: #f2f2f2;
    // }
  }
}
</style>
